
import upload from '@/components/upload/index.vue'
import { defineComponent, toRefs, computed, reactive, Ref, watch, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import editButton from '@/components/editButton/index.vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import Data from '@/model/common/Data'
import yTable from '@/components/yTable/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { FormItemListType } from '@/types/comm'
import modalForm from '@/components/modalForm/index.vue'
import FileItem from '@/model/common/fileItem'
import demandApi from '@/axios/api/demandApi'
import analysisOfPriceApi from '@/axios/api/analysisOfPrice'
import { deleteModal } from '@/utils/deleteModal'
import {
  ReqCustomerInformationClass,
  CustomerInformationClass,
} from '@/model/customerManagement/listedIndustry'
import {
  PageInformationCustomer,
  AddInformationCustomer,
  DeleteInformationCustomer,
  UpdateInformationCustomer,
  InfoInformationCustomer,
  AddFollowInformationCustomer,
  CancelFollowInformationCustomer,
  ImportInformationCustomer,
} from '@/axios/api/listedIndustry'
import { UploadFile } from '@/axios/api/common'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    editButton,
    yTable,
    FormItem,
    modalForm,
    upload,
    Drawer,
  },
  setup() {
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name, pageData.selectFrom)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    let route = useRoute()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    let urlData = reactive({
      type: 'subInput',
      prop: 'url',
      placeholder: '请输入链接地址',
      label: '链接地址',
      hidden: false,
    })
    let fileIdData = {
      type: 'slot',
      prop: 'fileId',
      placeholder: '请选择PDF文件',
      label: 'PDF文件',
      hidden: false,
    }
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new ReqCustomerInformationClass(),
      dataSource: [] as CustomerInformationClass[],
      uploadVisible: false,
      columns: [
        {
          title: '报告名称',
          dataIndex: 'informationName',
          width: 400,
          key: 'informationName',
          resizable: true,
        },
        {
          title: '是否推荐',
          dataIndex: 'isRecommend',
          width: 130,
          key: 'isRecommend',
          resizable: true,
          statusObj: {
            recommend: '推荐',
            unrecommend: '不推荐',
          },
        },
        {
          title: '行业名称',
          dataIndex: 'industryName',
          width: 180,
          key: 'industryName',
          resizable: true,
        },
        {
          title: '机构名称',
          dataIndex: 'mechanismName',
          width: 150,
          key: 'mechanismName',
          resizable: true,
        },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 150,
          key: 'releaseTime',
          resizable: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      productCodeList: [] as Data[],
      selectedRowKeys: [] as string[],
      loading: false,
      fetching: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'industryName',
          placeholder: '请输入行业名称',
          label: '行业名称',
        },
        {
          type: 'subInput',
          prop: 'informationName',
          placeholder: '请输入报告名称',
          label: '报告名称',
        },
        {
          type: 'subInput',
          prop: 'mechanismName',
          placeholder: '请输入机构名称',
          label: '机构名称',
        },

        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请选择是否关注',
          label: '是否关注:',
          optionList: [
            { label: '关注', value: 1 },
            { label: '未关注', value: 0 },
          ],
        },
        {
          type: 'subSelect',
          prop: 'isRecommend',
          placeholder: '请选择是否推荐',
          label: '是否推荐:',
          optionList: [
            { label: '推荐', value: 'recommend' },
            { label: '未推荐', value: 'unrecommend' },
          ],
        },
      ] as FormItemListType<CustomerInformationClass>,
      reportStatus: {
        0: '未填报',
        1: '部分填报',
        2: '全部填报',
      } as Data,
      optionList: [] as Data[],
      modalData: {
        title: '新增',
        form: { informationType: 'url' } as CustomerInformationClass,
        formItemList: [
          {
            type: 'subInput',
            prop: 'informationName',
            placeholder: '请输入资讯标题',
            label: '资讯标题',
            maxlength: 30,
          },
          {
            type: 'subInput',
            prop: 'mechanismName',
            placeholder: '请输入机构名称',
            label: '机构名称',
            maxlength: 30,
          },
          {
            type: 'subInput',
            prop: 'industryName',
            placeholder: '请输入行业名称',
            label: '行业名称',
            maxlength: 30,
          },
          {
            type: 'subDate',
            prop: 'releaseTime',
            placeholder: '请选择发布时间',
            label: '发布时间',
            valueFormat: 'YYYY-MM-DD hh:mm:ss',
            showTime: true,
          },
          {
            type: 'slot',
            prop: 'informationType',
            placeholder: '请选择内容展示',
            label: '内容展示',
          },
          urlData,
          fileIdData,
        ] as FormItemListType<Data>,
        rules: {
          informationName: [{ required: true, message: '资讯名称不可为空', trigger: 'blur' }],
          mechanismName: [{ required: true, message: '机构名称不可为空', trigger: 'blur' }],
          industryName: [{ required: true, message: '行业名称不可为空', trigger: 'blur' }],
          releaseTime: [{ required: true, message: '发布时间不可为空', trigger: 'change' }],
          informationType: [{ required: true, message: '信息类型不可为空', trigger: 'change' }],
          url: [
            { required: true, message: '链接地址不可为空', trigger: 'change' },
            {
              message: '链接地址格式不正确',
              trigger: 'blur',
              pattern:
                /^(?:https?:\/\/|ftp:\/\/|file:\/\/)(?:\S+(?::\S*)?@)?(?:[a-z0-9\-._~%]+|\[[a-z0-9\-._~%!$&'()*+,;=:]+\])(?::\d{2,5})?(?:\/\S*)?(?:\?\S*)?(?:#\S*)?$/i,
            },
          ],
          fileId: [{ required: true, message: '文件不可为空', trigger: 'blur' }],
        },
        visible: false,
        labelCol: { style: { width: '100px' } },
        width: '25%',
        ok: (formRef?: Ref) => {
          formRef?.value
            .validate()
            .then(() => {
              let api =
                pageData.modalData.title === '新增'
                  ? AddInformationCustomer
                  : UpdateInformationCustomer
              api(pageData.modalData.form)
                .then(() => {
                  pageData.modalData.visible = false
                  initeList()
                  message.success('成功')
                })
                .catch((err) => {
                  message.error(err)
                })
            })
            .catch((err: unknown) => {
              console.log(err)
            })
        },
      },
      fileName: '',
    })
    watch(
      () => pageData.modalData.form.informationType,
      (newVal) => {
        pageData.modalData.rules.url[0].required = newVal === 'url' ? true : false
        pageData.modalData.rules.fileId[0].required = newVal === 'url' ? false : true
        urlData.hidden = newVal === 'url' ? false : true
        fileIdData.hidden = newVal === 'url' ? true : false
      },
      {
        immediate: true,
      }
    )

    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
    }
    console.log(pageData.selectFrom)
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      PageInformationCustomer(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectProduct = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectProduct()
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })

    //搜索
    const search = () => {
      selectProduct()
    }

    //重置
    const reset = () => {
      pageData.selectFrom.data = new CustomerInformationClass()
      selectProduct()
    }

    // 全选事件
    const checkedChange = (checked: boolean) => {
      pageData.selectedRowKeys = checked
        ? (pageData.dataSource.map((item) => item.id) as string[]) || []
        : []
    }

    // 设置表单数据
    const onUpdateForm = (val: string | undefined, prop: keyof CustomerInformationClass) => {
      pageData.modalData.form[prop] = val as undefined
    }

    // 删除
    const deleteList = () => {
      const ok = () => {
        DeleteInformationCustomer(pageData.selectedRowKeys)
          .then(() => {
            message.success('删除成功')
            pageData.selectedRowKeys = []
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel)
    }

    // 导入函数
    const importFile = (param: FileItem) => {
      let file = param.file as FileItem
      // 获取文件后缀名
      const FILE_SUFFIX_NAME = file.name?.substring(file.name.lastIndexOf('.'))
      if (!'.pdf'.includes((FILE_SUFFIX_NAME as string)?.toLocaleLowerCase())) {
        message.error('请上传正确的文件类型')
        return false
      } else if (3 && file.size && file.size > 3 * 1024 * 1024) {
        message.error(`上传文件超出限制大小，请上传${3}M内文件！`)
        return false
      }
      const formData = new FormData()
      formData.append('file', param.file as Blob)
      UploadFile(formData)
        .then((res) => {
          pageData.modalData.form.fileId = res.id
          pageData.fileName = (param.file as FileItem).name as string
          message.success('导入成功')
        })
        .catch((err) => {
          message.error(err)
        })
    }

    // 删除文件
    const delFile = () => {
      pageData.fileName = ''
      pageData.modalData.form.fileId = ''
    }

    // 设置是否推荐

    const setRecommend = (
      record: CustomerInformationClass,
      isRecommend: 'recommend' | 'unrecommend'
    ) => {
      let data = JSON.parse(JSON.stringify(record))
      data.isRecommend = isRecommend
      UpdateInformationCustomer(data)
        .then(() => {
          message.success('成功')
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    // 批量导出
    const exportFile = (id?: string) => {
      analysisOfPriceApi
        .analysisOfPriceApiExport(id ? [id] : pageData.selectedRowKeys)
        .then((res) => {
          console.log(res)
          let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' })
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.download = ''
          a.href = url
          a.click()
          a.remove()
          message.success('导出成功')
        })
        .catch((err) => {
          message.error(err)
        })
    }

    const follow = (data: CustomerInformationClass) => {
      if (data.isFollow === 1) {
        CancelFollowInformationCustomer(data.id as string)
          .then(() => {
            message.success('取消关注成功')
            data.isFollow = 0
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        AddFollowInformationCustomer(data.id as string)
          .then(() => {
            message.success('关注成功')
            data.isFollow = 1
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }

    const updateDetail = (data: CustomerInformationClass) => {
      console.log(123456)

      InfoInformationCustomer(data.id as string)
        .then((res) => {
          pageData.modalData.form = res
          pageData.fileName = res.copFileInfoVO?.fullFileName as string
          pageData.modalData.title = '修改'
          pageData.modalData.visible = true
        })
        .catch((err) => {
          message.error(err)
        })
    }

    const openAdd = () => {
      pageData.modalData.form = {} as CustomerInformationClass
      pageData.modalData.form.informationType = 'url'
      pageData.modalData.title = '新增'
      pageData.modalData.visible = true
    }

    // 跳转详情
    const goDetail = (record: CustomerInformationClass) => {
      if (record.informationType === 'url') {
        window.open(record.url)
      } else if (
        record.informationType === 'file' &&
        record.fileId &&
        record.copFileInfoVO?.fullFileName
      ) {
        demandApi
          .DemanddownloadSec(record.fileId as string)
          .then((res) => {
            const blob = new Blob([res], { type: 'application/pdf' })
            const pdfUrl = URL.createObjectURL(blob)
            window.open(pdfUrl)
          })
          .catch(() => {
            message.error('预览失败')
          })
        // const a = document.createElement('a')
        // a.download = record.copFileInfoVO?.fullFileName as string
        // a.href = `/api/file/preview?id=${record.fileId}`
        // a.click()
        // a.remove()
      }
    }
    return {
      goDetail,
      openAdd,
      updateDetail,
      follow,
      exportFile,
      importFile,
      delFile,
      deleteList,
      onUpdateForm,
      ...toRefs(pageData),
      search,
      reset,
      buttonObj,
      currentConfigure,
      selectProduct,
      checkedChange,
      setRecommend,
      initeList,
      ImportInformationCustomer,
      updateColumns,
    }
  },
})
