/**
 * 同行信息实体类和接口
 */
import { ReqPage } from '@/model/common/reqPage'
import Data from '../common/Data'
export class CustomerInformationClass {
  id: string | undefined // "string //主键"
  informationName: string | undefined // 报告名称
  isRecommend: string | undefined // 是否推荐;recommend：推荐，unrecommend：未推荐
  industryName: string | undefined // 行业名称
  mechanismName: number | undefined //  机构名称
  releaseTime: string | undefined // 发布时间
  informationType: string | undefined // 信息类型;url：链接,file：文件
  fileId: string | undefined // 文件id
  isDeleted: string | undefined //  	是否删除;undeleted：未删除 deleted：已删除
  createdBy: string | undefined // 创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined // 更新人
  updatedTime: string | undefined // 	更新时间
  url: string | undefined // 链接地址
  isFollow: 0 | 1 | undefined // 0：未关注  1：关注
  copFileInfoVO?: Data //文件详细信息
}
export class ReqCustomerInformationClass extends ReqPage {
  data: CustomerInformationClass
  constructor() {
    super()
    this.data = new CustomerInformationClass()
  }
}

export interface RegCustomerInformationList extends ReqPage {
  data: CustomerInformationClass[]
}
