/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2023-04-09 15:16:05
 * @LastEditors: 06284
 * @LastEditTime: 2023-05-12 09:20:44
 */
import { MissingType } from '@/types/comm'
import { AxiosRequestConfig } from 'axios'
import { $axios } from '../config/axios'

/**
 * @description:导入
 * @param {CustomerInformationClass} data
 * @return {*}
 * @see:http://wiki.agile.dgyiheda.com/wiki/kb/agile126?pageCode=7132
 */
export const UploadFile = (
  data: FormData,
  config?: AxiosRequestConfig
): Promise<string | MissingType> => {
  return $axios.post('/api/file/uploadFile', data, config)
}
